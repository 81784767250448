import type { RouterConfig } from 'nuxt/schema';
import type { RouteLocationNormalized } from '#vue-router';
import { appPageTransition as defaultPageTransition } from '#build/nuxt.config.mjs';

export default {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    // overwriting this method deactivates auto scrolling to top on navigation as implemented by nuxt
    // this way, we are also able to restore a scroll position
    if (
      to.name?.toString().startsWith('index___') ||
      to.name?.toString().startsWith('content-hub-slug___')
    ) {
      return false;
    }

    // Copied from nuxt code: @see https://github.com/nuxt/nuxt/blob/3c1582b6ff668fa331f61ad535e395276792cee2/packages/nuxt/src/pages/runtime/router.options.ts#L14
    // Wait for `page:transition:finish` or `page:finish` depending on if transitions are enabled or not
    const nuxtApp = useNuxtApp();

    const hasTransition = (route: RouteLocationNormalized) =>
      !!(route.meta.pageTransition ?? defaultPageTransition);
    const hookToWait =
      hasTransition(from) && hasTransition(to)
        ? 'page:transition:finish'
        : 'page:finish';
    return new Promise((resolve) => {
      nuxtApp.hooks.hookOnce(hookToWait, async () => {
        await new Promise((resolve) => setTimeout(resolve, 0));
        resolve({ top: 0 });
      });
    });
  },
} satisfies RouterConfig;
