export default defineNuxtRouteMiddleware((to) => {
  if (import.meta.server) {
    // if there is a query parameter lang=fr, then redirect to fr if on home page, and in any case remove the query parameter
    if (to.query.lang === 'fr') {
      const newPath = to.path === '/' ? '/fr' : to.path;

      return navigateTo({
        path: newPath,
        query: { ...to.query, lang: undefined },
      });
    }
  }
});
