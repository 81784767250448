<template>
  <div class="error-page">
    <div v-if="error?.statusCode === 404" class="error404 layout">
      <p class="eyebrow text-md">404</p>
      <h1 class="title">
        <span class="line1 text-4xl">{{ t('titleLine1') }}</span
        ><br />
        <span class="line2 text-4xl-display">{{ t('titleLine2') }}</span>
      </h1>
      <p class="text text-lg">{{ t('text') }}</p>
      <a href="/">
        {{ t('backToHome') }}
        <span class="arrow"></span>
      </a>
    </div>
    <div v-else>
      <pre>{{ error }}</pre>
      <button @click="handleError()">Clear error</button>
    </div>
  </div>
</template>

<i18n lang="yaml">
fr:
  titleLine1: Mauvaise
  titleLine2: direction ?
  text: La page que tu cherches n'existe pas. Regarde donc encore une fois un peu sur notre site.
  backToHome: Retour à home
de:
  titleLine1: Falsch
  titleLine2: abgebogen?
  text: Die Seite, die du suchst, gibt es nicht. Schau dich doch nochmals ein wenig auf unserer Seite um.
  backToHome: Zurück zu Home
</i18n>

<script setup lang="ts">
const error = useError();
const { t } = useI18n();
function handleError() {
  clearError({ redirect: '/' });
}
</script>

<style lang="scss" scoped>
.error-page {
  padding: 2rem;

  .layout {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
</style>
