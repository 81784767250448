import type { InjectionKey } from 'vue';
import type { TranslationPanelFragment } from '#gql';

export const translationInjectionKey = Symbol(
  'translationInjectionKey',
) as InjectionKey<(key: keyof TranslationPanelFragment) => string | null>;

export function useInternalI18n() {
  const translations = inject(translationInjectionKey);
  return {
    dt(key: keyof TranslationPanelFragment) {
      return translations?.(key) ?? null;
    },
  };
}
