import type { RouteLocationRaw } from 'vue-router';

export function getRouteObject(
  slug: string,
  __typename: string,
  locale: string,
) {
  const route: RouteLocationRaw = {
    name: '',
    params: {
      slug,
    },
  };

  if (slug === 'home') {
    route.name = localizedOrDefaultRouteName('index');
    route.params = undefined;
  } else if (__typename === 'ContentHubHome') {
    route.name = localizedOrDefaultRouteName('content-hub');
    route.params = undefined;
  } else if (__typename === 'ArticleTypeRecord') {
    route.name = localizedOrDefaultRouteName('content-hub-slug');
  } else if (__typename === 'ArticleRecord') {
    route.name = localizedOrDefaultRouteName('articles-slug');
  } else if (__typename === 'PlantTypeRecord') {
    route.name = localizedOrDefaultRouteName('plant-types-slug');
  } else if (__typename === 'PageRecord') {
    route.name = localizedOrDefaultRouteName('slug');
  }

  function localizedOrDefaultRouteName(baseName: string): string {
    // TODO: env variable in frontend nicht vorhanden? deshalb hardcoded im moment
    // if (locale === process.env.I18N_DEFAULT_LOCALE) {
    if (locale === 'de') {
      return `${baseName}___${locale}___default`;
    }

    return `${baseName}___${locale}`;
  }

  return route;
}
