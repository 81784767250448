<template>
  <div v-if="false" class="color-scheme-switch">
    <svg
      class="icon"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.4 8C14.4 11.5346 11.5346 14.4 8 14.4V1.6C11.5346 1.6 14.4 4.46538 14.4 8ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
        fill="currentColor"
      />
    </svg>
    <label class="label -auto" for="color-scheme-auto">Auto</label>
    <label class="label -light" for="color-scheme-light">Light</label>
    <label class="label -dark" for="color-scheme-dark">Dark</label>
    <ul class="list">
      <li class="item">
        <input
          id="color-scheme-auto"
          v-model="colorScheme"
          class="input"
          name="color-scheme"
          type="radio"
          value="light dark"
          checked
        />
      </li>
      <li class="item">
        <input
          id="color-scheme-light"
          v-model="colorScheme"
          class="input"
          name="color-scheme"
          type="radio"
          value="light"
        />
      </li>
      <li class="item">
        <input
          id="color-scheme-dark"
          v-model="colorScheme"
          class="input"
          name="color-scheme"
          type="radio"
          value="dark"
        />
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';

const colorScheme = ref('light dark');

onMounted(() => {
  colorScheme.value = localStorage.getItem('colorScheme') || 'light dark';
});

watch(colorScheme, (newVal) => {
  localStorage.setItem('colorScheme', newVal);
});
</script>

<style lang="scss">
:root {
  // TODO: fix dark color scheme for now
  color-scheme: dark;
  //color-scheme: light dark;
  //
  //&:has(input[name='color-scheme'][value='light']:checked) {
  //  color-scheme: light;
  //}
  //
  //&:has(input[name='color-scheme'][value='dark']:checked) {
  //  color-scheme: dark;
  //}

  // If transitions needed:
  //transition:
  //    color var(--base-transition-duration-fast) var(--base-transition-easing),
  //    background-color var(--base-transition-duration-fast)
  //    var(--base-transition-easing),
  //    border-color var(--base-transition-duration-fast)
  //    var(--base-transition-easing);
}
</style>

<style scoped lang="scss">
.color-scheme-switch {
  display: flex;
  color: var(--base-color-foreground-faded);
  position: relative;

  &:has(input[name='color-scheme'][value='light dark']:checked) {
    > .label.-auto,
    > .label.-dark {
      display: none;
    }
  }

  &:has(input[name='color-scheme'][value='light']:checked) {
    > .label.-auto,
    > .label.-light {
      display: none;
    }
  }

  &:has(input[name='color-scheme'][value='dark']:checked) {
    > .label.-light,
    > .label.-dark {
      display: none;
    }
  }

  &:has(input[name='color-scheme'][value='light']:checked),
  &:has(input[name='color-scheme'][value='dark']:checked) {
    color: var(--base-color-brand);

    > .icon {
      rotate: 180deg;
    }
  }

  > .label {
    position: absolute;
    inset: 0;
    opacity: 0;
    overflow: hidden;
    cursor: pointer;
  }

  > .icon {
    width: 1rem;
    transition: rotate var(--base-transition-duration)
      var(--base-transition-easing);
  }

  > .list {
    display: none;
  }
}
</style>
