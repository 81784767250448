<template>
  <div class="icon-element" />
</template>

<script setup lang="ts">
const props = defineProps<{
  iconUrl: string;
}>();

const cssIconUrl = `url(${props.iconUrl})`;
</script>

<style scoped lang="scss">
.icon-element {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-color: currentColor;
  mask-image: v-bind(cssIconUrl);
  mask-size: contain;
  mask-repeat: no-repeat;
}
</style>
