<template>
  <nav
    id="main-navigation"
    class="main-navigation"
    :aria-label="$t('navigation.ariaLabelMainNavigation')"
  >
    <ul class="list text-3xl-vs text-sm-vl">
      <li class="item -overview">
        <NuxtLink :to="getRoute('home', 'HomePageRecord')" class="link">{{
          $t('navigation.home')
        }}</NuxtLink>
      </li>
      <li class="item">
        <NuxtLink
          :to="
            getRoute(plantTypeLink.translatedSlug!, plantTypeLink.__typename)
          "
          class="link"
          >{{ $t('navigation.plantTypes') }}
          <small class="suffix text-xs">{{ plantCount }}</small></NuxtLink
        >
      </li>
      <li class="item">
        <NuxtLink
          :to="getRoute(aboutUsLink.translatedSlug!, aboutUsLink.__typename)"
          class="link"
          >{{ aboutUsLink.title }}</NuxtLink
        >
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import type { PageLinkFragment, PlantTypeLinkFragment } from '#gql';
import { useInternalRoute } from '~/datocms/useInternalRoute';
import { useGlobalData } from '~/datocms/useGlobalData';

const { getRoute } = useInternalRoute();

const { mainNavigation, allPlantsMeta } = useGlobalData();

const navLinks = computed(() => {
  if (mainNavigation.value) {
    return (mainNavigation.value.navLinks ?? [])
      .filter((l) => l.link && l.link?.slug !== 'home')
      .map((l) => l.link!);
  }

  return [];
});

const plantTypeLink = computed(
  () => navLinks.value[0] as unknown as PlantTypeLinkFragment,
);
const plantCount = allPlantsMeta.value?.count ?? 0;

const aboutUsLink = computed(
  () => navLinks.value[1] as unknown as PageLinkFragment,
);
</script>

<style scoped lang="scss">
.main-navigation {
  > .list {
    > .item {
      color: var(--base-color-foreground-faded);

      &.-active {
        color: var(--base-color-brand);
      }

      @media (hover: hover) {
        &:hover {
          color: var(--base-color-foreground);
        }
      }

      > .link {
        text-decoration: none;
        color: inherit;
        white-space: nowrap;

        &.-link-active {
          color: var(--base-color-foreground);
        }

        > .suffix {
          color: var(--color-lime-400);
          vertical-align: top;
        }
      }
    }
  }

  // Small
  @media (--vs) {
    > .list {
      display: grid;
      gap: 1.5rem;
    }
  }

  // Large
  @media (--vl) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    > .list {
      display: flex;
      gap: 3.5rem;

      > .item.-overview {
        display: none;
      }
    }
  }
}
</style>
