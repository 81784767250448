import { default as _91slug_93FNbD9oDbfEMeta } from "/opt/render/project/src/pages/[slug].vue?macro=true";
import { default as _91slug_93Py2d5qR7w9Meta } from "/opt/render/project/src/pages/articles/[slug].vue?macro=true";
import { default as _91slug_93gTIDQC0gTgMeta } from "/opt/render/project/src/pages/content-hub/[slug].vue?macro=true";
import { default as content_45hubVmXkxZU55fMeta } from "/opt/render/project/src/pages/content-hub.vue?macro=true";
import { default as indexYy8SidDWaAMeta } from "/opt/render/project/src/pages/index.vue?macro=true";
import { default as _91slug_93MKlY78CV2JMeta } from "/opt/render/project/src/pages/plant-types/[slug].vue?macro=true";
import { default as component_45stubzFO8P7pzWFMeta } from "/opt/render/project/src/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubzFO8P7pzWF } from "/opt/render/project/src/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___de___default",
    path: "/:slug()",
    component: () => import("/opt/render/project/src/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___de",
    path: "/de/:slug()",
    component: () => import("/opt/render/project/src/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___fr",
    path: "/fr/:slug()",
    component: () => import("/opt/render/project/src/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "articles-slug___de___default",
    path: "/artikel/:slug()",
    component: () => import("/opt/render/project/src/pages/articles/[slug].vue").then(m => m.default || m)
  },
  {
    name: "articles-slug___de",
    path: "/de/artikel/:slug()",
    component: () => import("/opt/render/project/src/pages/articles/[slug].vue").then(m => m.default || m)
  },
  {
    name: "articles-slug___fr",
    path: "/fr/article/:slug()",
    component: () => import("/opt/render/project/src/pages/articles/[slug].vue").then(m => m.default || m)
  },
  {
    name: "content-hub___de___default",
    path: "/uebersicht",
    component: () => import("/opt/render/project/src/pages/content-hub.vue").then(m => m.default || m),
    children: [
  {
    name: "content-hub-slug___de___default",
    path: ":slug()/:topicSlug?",
    component: () => import("/opt/render/project/src/pages/content-hub/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "content-hub___de",
    path: "/de/uebersicht",
    component: () => import("/opt/render/project/src/pages/content-hub.vue").then(m => m.default || m),
    children: [
  {
    name: "content-hub-slug___de",
    path: ":slug()/:topicSlug?",
    component: () => import("/opt/render/project/src/pages/content-hub/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "content-hub___fr",
    path: "/fr/apercu",
    component: () => import("/opt/render/project/src/pages/content-hub.vue").then(m => m.default || m),
    children: [
  {
    name: "content-hub-slug___fr",
    path: ":slug()/:topicSlug?",
    component: () => import("/opt/render/project/src/pages/content-hub/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index___de___default",
    path: "/",
    component: () => import("/opt/render/project/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/opt/render/project/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/opt/render/project/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "plant-types-slug___de___default",
    path: "/anlagen/:slug()",
    component: () => import("/opt/render/project/src/pages/plant-types/[slug].vue").then(m => m.default || m)
  },
  {
    name: "plant-types-slug___de",
    path: "/de/anlagen/:slug()",
    component: () => import("/opt/render/project/src/pages/plant-types/[slug].vue").then(m => m.default || m)
  },
  {
    name: "plant-types-slug___fr",
    path: "/fr/installations/:slug()",
    component: () => import("/opt/render/project/src/pages/plant-types/[slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/stellungnahmen/",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/anlaesse/",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/kurse/",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/fakten/abfallverwertung/",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/fakten/energie-charts",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/fakten/downloads/",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/verband/ueber-uns/",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/verband/taetigkeiten/",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/verband/organisation/",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/verband/kommissionen/",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/verband/arbeitssicherheit/",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/anlagegruppen/kva/",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/anlagegruppen/deponien/",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/anlagegruppen/sonderabfall/",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/anlagegruppen/sortierung-und-recycling/",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/anlagegruppen/kompostierung-und-vergaerung/",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/anlagegruppen/andere-thermische-anlagen/",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/datenschutzerklaerung/",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/avis/",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/evenements/",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/cours/",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/donnees/valorisation-des-dechets/",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/donnees/energie-charts/",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/donnees/downloads/",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/association/l-ased-en-bref/",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/association/activites/",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/association/organisation/",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/association/commissions-techniques/",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/association/commissions-de-formation/",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/association/la-securite-au-travail/",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/categories-de-traitement/uvtd/",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/categories-de-traitement/decharges/",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/categories-de-traitement/dechets-speciaux/",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/categories-de-traitement/tri-et-recyclage/",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/categories-de-traitement/compostage-et-methanisation/",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/categories-de-traitement/autres-installations-thermiques/",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/declaration_de_protection_de_donnees/",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/mentions-legales/",
    component: component_45stubzFO8P7pzWF
  }
]