import type { InjectionKey, Ref } from 'vue';
import type {
  GetGlobalSettingsQuery,
  GetMainNavigationQuery,
  GetTranslatedSlugsQuery,
} from '#gql';

export interface GlobalData {
  siteInfo: GetGlobalSettingsQuery['siteInfo'] | null;
  translationPanel: GetGlobalSettingsQuery['translationPanel'] | null;
  allPlantsMeta: GetMainNavigationQuery['allPlantsMeta'] | null;
  mainNavigation: GetMainNavigationQuery['mainNavigation'] | null;
  translatedSlugs: GetTranslatedSlugsQuery | null;
}

export const globalDataInjectionKey = Symbol(
  'globalDataInjectionKey',
) as InjectionKey<Ref<GlobalData>>;

export function useGlobalData() {
  const globalData = inject(globalDataInjectionKey)!;
  return {
    siteInfo: computed(() => globalData.value.siteInfo),
    translationPanel: computed(() => globalData.value.translationPanel),
    mainNavigation: computed(() => globalData.value.mainNavigation),
    allPlantsMeta: computed(() => globalData.value.allPlantsMeta),
    translatedSlugs: computed(() => globalData.value.translatedSlugs),
  };
}
