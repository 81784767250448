<template>
  <a
    v-if="link.linkType === 'external'"
    class="link"
    :href="link.externalUrl ?? undefined"
    :target="link.openLinkInNewWindow ? '_blank' : '_self'"
  >
    <slot>{{ link.linkText }}</slot>
  </a>
  <NuxtLink
    v-else-if="
      link.linkType === 'internal' &&
      link.internalUrl &&
      '__typename' in link.internalUrl
    "
    class="link"
    :to="
      getRoute(
        link.internalUrl.translatedSlug ?? '',
        link.internalUrl.__typename,
      )
    "
    :target="link.openLinkInNewWindow ? '_blank' : '_self'"
  >
    <slot>{{ link.linkText }}</slot>
  </NuxtLink>
</template>

<script setup lang="ts">
import type { LinkFragment } from '#gql';
import { useInternalRoute } from '~/datocms/useInternalRoute';

defineProps<{
  link: LinkFragment;
}>();

const { getRoute } = useInternalRoute();
</script>

<style scoped lang="scss">
.link {
  text-decoration: none;
}
</style>
