interface StorageArticleFilter {
  locale: string | null;
  articleTypeSlug: string | null;
  topicSlug: string | null;
}

type StorageArticleViewModel = 'card' | 'list';

const selectedArticleFilter = useSessionStorage<StorageArticleFilter>(
  'article.filter',
  {
    locale: null,
    articleTypeSlug: null,
    topicSlug: null,
  },
);

const selectedArticleView = useSessionStorage<StorageArticleViewModel>(
  'article.view',
  'card',
);

export const lastContentHubScrollTop = ref(0);

export function initializeArticleContentHub() {
  const { beforeEach } = useRouter();
  const { locale } = useI18n();

  beforeEach((to, from) => {
    // when navigating away from content hub, save current scroll position to restore it later
    if (
      from.name?.toString().startsWith('index___') ||
      from.name?.toString().startsWith('content-hub-slug___')
    ) {
      if (import.meta.client) {
        lastContentHubScrollTop.value = window.scrollY;
      }
    }

    if (to.name?.toString().startsWith('index___')) {
      if (
        selectedArticleFilter.value.locale === locale.value &&
        (selectedArticleFilter.value.articleTypeSlug ||
          selectedArticleFilter.value.topicSlug ||
          selectedArticleView.value)
      ) {
        return {
          name:
            locale.value === 'de'
              ? 'content-hub-slug___de___default'
              : `content-hub-slug___${locale.value}`,
          params: {
            slug: selectedArticleFilter.value.articleTypeSlug,
            topicSlug: selectedArticleFilter.value.topicSlug,
          },
          query: {
            view: selectedArticleView.value,
          },
        };
      } else {
        selectedArticleFilter.value = null;
        selectedArticleView.value = null;
        selectedArticleView.value = null;
      }
    } else if (to.name?.toString().startsWith('content-hub-slug___')) {
      selectedArticleView.value = to.query.view as StorageArticleViewModel;

      selectedArticleFilter.value = {
        locale: locale.value,
        articleTypeSlug: (to.params.slug as string) || null,
        topicSlug: (to.params.topicSlug as string) || null,
      };
    }
  });
}
