import type { NavLinkFragment } from '#gql';
import { getRouteObject } from '~/datocms/getRouteObject';

export function useInternalRoute() {
  const { locale } = useI18n();

  return { getRoute, getNavLink };

  function getRoute(slug: string, __typename: string) {
    return getRouteObject(slug, __typename, locale.value);
  }

  function getNavLink(link: NavLinkFragment['link']) {
    if (!link) {
      return null;
    }

    if (
      !('_allTranslatedSlugLocales' in link) ||
      !link._allTranslatedSlugLocales
    ) {
      return null;
    }

    const linkData = link._allTranslatedSlugLocales.find(
      (i) => i.locale === locale.value,
    );

    if (linkData && linkData.value) {
      return getRouteObject(linkData.value, link.__typename, locale.value);
    }

    return null;
  }
}
