<template>
  <footer v-if="siteInfo" class="site-footer">
    <HomeLink class="homelink" />
    <div class="minibar">
      <LangSwitch :key="locale" class="langswitch" />
    </div>
    <div class="address">
      <h2 class="eyebrow text-sm">{{ siteInfo.addressTitle }}</h2>
      <address class="address text-xl-vs text-3xl-vl">
        <StructuredText :data="siteInfo.address" />
        <p>{{ siteInfo.eMail }}</p>
      </address>
    </div>
    <ul class="navigation text-lg-vs text-2xl-vl">
      <li
        v-for="link in siteInfo.siteLinks"
        :key="`site-link-${link.id}`"
        class="item"
      >
        <Link :link="link">
          {{ link.linkText }}
          <span
            v-if="link.internalUrl?.__typename === 'PlantTypeRecord'"
            class="suffix text-sm"
            >{{ allPlantsMeta?.count }}</span
          ></Link
        >
      </li>
    </ul>
    <ul class="links text-sm-vs text-md-vl">
      <li
        v-for="link in siteInfo.legalInformationLinks"
        :key="`legal-information-link-${link.id}`"
        class="item"
      >
        <Link :link="link" />
      </li>
    </ul>
    <div class="copyright text-sm">
      &copy; {{ new Date().getFullYear() }} VBSA
    </div>
    <!-- TODO: no cookie consent for now, use dummy div instead   -->
    <div class="cookies" />
    <!--    <div class="cookies text-sm">COOKIE CONSENT 🍪</div>-->
    <div class="siteby text-sm">
      <a href="https://gridonic.ch">Website by Gridonic</a>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { StructuredText } from 'vue-datocms';
import HomeLink from '~/components/partials/HomeLink.vue';
import LangSwitch from '~/components/site-header/LangSwitch.vue';
import Link from '~/components/partials/Link.vue';
import { useGlobalData } from '~/datocms/useGlobalData';

const { locale } = useI18n();
const { siteInfo, allPlantsMeta } = useGlobalData();
</script>

<style scoped lang="scss">
.site-footer {
  display: grid;
  background-color: var(--color-black);

  a {
    color: var(--base-color-foreground-faded);
    text-decoration: none;

    @media (hover: hover) {
      &:hover {
        color: var(--color-stone-100);
      }
    }
  }

  > .minibar {
    display: flex;
    justify-content: end;
  }

  > .address {
    > .eyebrow {
      color: var(--base-color-foreground-faded);
      margin-bottom: 0.5rem;
    }
  }

  > .navigation {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    > .item > .link {
      > .suffix {
        color: var(--color-lime-400);
        vertical-align: top;
      }
    }
  }

  > .links {
    display: flex;
    flex-direction: column;

    @media (--vs) {
      gap: 0.25rem;
    }

    @media (--vl) {
      gap: 1rem;
    }
  }

  > .copyright,
  > .cookies,
  > .siteby {
    color: var(--base-color-foreground-faded);
  }

  // Small
  @media (--vs) {
    padding: 2rem 1.5rem;
    grid-template-columns: repeat(2, 1fr);

    > .address {
      grid-column: 1 / -1;
      margin-top: 3.625rem;
    }

    > .navigation {
      grid-column: 1 / -1;
      margin-top: 3.5rem;
    }

    > .links {
      grid-column: 1 / -1;
      margin-top: 2rem;
    }

    > .cookies {
      order: 10;
      grid-column: 1 / -1;
      text-align: right;
      margin-top: 5.25rem;
    }

    > .copyright {
      order: 11;
      margin-top: 1rem;
    }

    > .siteby {
      order: 12;
      text-align: right;
      margin-top: 1rem;
    }
  }

  // Large
  @media (--vl) {
    gap: 4rem;
    padding: 2.875rem 4rem 2.875rem;
    grid-template-columns: 645fr 320fr 300fr;

    > .homelink {
      grid-column: 1 / 3;
    }

    > .minibar {
      grid-column: 3 / -1;
    }

    > .address {
      grid-column: 1 / 2;
      margin-top: 15.188rem;
    }

    > .navigation {
      margin-top: 17.312rem;
    }

    > .links {
      margin-top: 17.312rem;
      text-align: right;
    }

    > .copyright,
    > .cookies {
      margin-top: 6.25rem;
    }

    > .siteby {
      margin-top: 6.25rem;
      text-align: right;
    }

    @media (min-width: 2000px) {
      padding-left: calc(4rem + var(--site-outer-margin));
      padding-right: calc(4rem + var(--site-outer-margin));
    }
  }
}
</style>
