<script setup lang="ts">
import MainNavigation from '~/components/site-header/MainNavigation.vue';
import LangSwitch from '~/components/site-header/LangSwitch.vue';
import HomeLink from '~/components/partials/HomeLink.vue';
import { useInternalRoute } from '~/datocms/useInternalRoute';

const { locale } = useI18n();
const { getRoute } = useInternalRoute();

function openMenu() {
  const els = document.querySelectorAll('.site-header-content');
  els.forEach((el) => {
    el.classList.add('-is-open');
  });
}
</script>

<template>
  <div class="site-header-content">
    <HomeLink class="homelink -default" />
    <div class="homelink -back backlink">
      <NuxtLink class="link" :to="getRoute('home', 'HomePageRecord')"
        ><span class="icon"
          ><IconElement :icon-url="`/images/icons/arrow-left.svg`" /></span
        ><span class="label">{{ $t('article.backToOverview') }}</span></NuxtLink
      >
    </div>
    <MainNavigation :key="locale" class="mainnavigation" />
    <LangSwitch :key="locale" class="langswitch" />
    <ColorSchemeSwitch class="colorschemeswitch" />
    <button class="hamburger" type="button" @click="openMenu()">
      <span class="label text-sm">{{ $t('navigation.menu') }}</span>
      <svg
        class="icon"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.349 4.03931H1.65112"
          stroke="currentColor"
          stroke-width="1.71"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22.349 12H1.65112"
          stroke="currentColor"
          stroke-width="1.71"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22.349 19.9607H1.65112"
          stroke="currentColor"
          stroke-width="1.71"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  </div>
</template>

<style scoped lang="scss">
.site-header-content {
  display: flex;
  align-items: center;

  @media (--vs) {
    > .mainnavigation,
    > .langswitch,
    > .colorschemeswitch {
      display: none;
    }
  }

  > .homelink {
    margin-right: auto;
    min-width: 60%;

    &.-back {
      display: none;
    }

    @media (--vs) {
      .app.-is-article-page & {
        &.-default {
          display: none;
        }

        &.-back {
          display: initial;
        }
      }
    }
  }

  > .mainnavigation {
    @media (--vl) {
      margin-right: 3.5rem;
    }
  }

  > .colorschemeswitch {
    margin-left: 1.5rem;
  }

  > .hamburger {
    @media (--vs) {
      display: grid;
      align-items: center;
      grid-template-columns: min-content 1.5rem;
      gap: 0.75rem;
      appearance: none;
      background: none;
      border: none;
      padding: 0;
      margin: 0;
      flex-shrink: 0;
      cursor: pointer;
      color: var(--base-color-foreground-faded);

      > .label {
        white-space: nowrap;
      }

      > .icon {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    @media (--vl) {
      display: none;
    }
  }

  > .backlink {
    pointer-events: initial;

    > .link {
      @extend .text-sm;

      display: flex;
      align-items: center;
      gap: 1rem;
      text-decoration: none;
      color: var(--color-stone-300);

      > .icon {
        border: 1px solid var(--color-stone-600);
        width: 1.5rem;
        height: 1.5rem;

        .icon-element {
          width: 100%;
          height: 100%;
        }
      }

      &:hover {
        color: var(--color-stone-200);
      }
    }
  }
}
</style>
