<template>
  <nav
    v-if="currentPage?.allTranslatedSlugs"
    class="lang-switch"
    :aria-label="$t('locale.ariaLabelNavigation')"
  >
    <svg
      class="icon"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{{ $t('locale.infoCurrentLanguage') }}</title>
      <g clip-path="url(#clip0_3596_5932)">
        <path
          d="M8.00036 14.8994C11.8108 14.8994 14.8996 11.8105 14.8996 8.00012C14.8996 4.18975 11.8108 1.10083 8.00036 1.10083C4.18999 1.10083 1.10107 4.18975 1.10107 8.00012C1.10107 11.8105 4.18999 14.8994 8.00036 14.8994Z"
          stroke="currentColor"
          stroke-width="1.14"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.10107 8.00012H14.8996"
          stroke="currentColor"
          stroke-width="1.14"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.6538 8.00012C10.5235 10.5231 9.59418 12.9393 8.00025 14.8994C6.40633 12.9393 5.47702 10.5231 5.34668 8.00012C5.47702 5.47711 6.40633 3.06092 8.00025 1.10083C9.59418 3.06092 10.5235 5.47711 10.6538 8.00012Z"
          stroke="currentColor"
          stroke-width="1.14"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3596_5932">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
    <ul class="list text-sm" :aria-label="$t('locale.ariaLabelList')">
      <li
        v-for="it in currentPage?.allTranslatedSlugs ?? []"
        :key="`${it.locale}-${it.value}`"
        class="item"
        :class="{ '-active': locale === it.locale }"
      >
        <!--
          We do not use NavLink here, or else the vue will try make a dato cms graphql call from
          the client, which is not allowed.
          As a workaround, we make an exception here and use a link to make a full page reload.
        -->
        <a
          :lang="String(it.locale)"
          :hreflang="String(it.locale)"
          :title="$t('locale.showPageInLang')"
          class="link"
          :href="localizedRouteHref(it)"
        >
          {{ it.locale }}
        </a>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import type { SiteLocale } from '#gql/default';
import { getRouteObject } from '~/datocms/getRouteObject';
import { useGlobalData } from '~/datocms/useGlobalData';

const { locale, t } = useI18n();

const app = useNuxtApp();

const { translatedSlugs } = useGlobalData();

const { resolve, currentRoute } = useRouter();

const allRoutes = [
  translatedSlugs.value?.homePage,
  ...(translatedSlugs.value?.allPages ?? []),
  ...(translatedSlugs.value?.allArticles ?? []),
  ...(translatedSlugs.value?.allPlantTypes ?? []),
  ...(translatedSlugs.value?.allArticleTypes ?? []),
  {
    __typename: 'ArticleTypeRecord',
    slug: 'all-types',
    translatedSlug: t('contentHub.allArticlesSlug'),
    allTranslatedSlugs: [
      {
        locale: 'de',
        value: t('contentHub.allArticlesSlug', 1, { locale: 'de' }),
      },
      {
        locale: 'fr',
        value: t('contentHub.allArticlesSlug', 1, { locale: 'fr' }),
      },
    ],
  },
].filter((r) => !!r);

const currentPage = computed(function () {
  if (!app._route.params.slug) {
    return allRoutes.find((r) => r.slug === 'home');
  }

  return allRoutes.find((r) => r.translatedSlug === app._route.params.slug);
});

function localizedRouteHref(slugInfo: {
  value?: string | null;
  locale?: SiteLocale | null;
}): string {
  const targetRoute = getRouteObject(
    slugInfo.value!,
    currentPage.value?.__typename ?? '',
    slugInfo.locale ?? 'de',
  );
  if (
    currentPage.value?.__typename === 'ArticleTypeRecord' &&
    currentRoute.value.params.topicSlug
  ) {
    const translatedTopicSlug = (translatedSlugs.value?.allTopics ?? [])
      .find((r) => r.translatedSlug === currentRoute.value.params.topicSlug)
      ?.allTranslatedSlugs?.find((s) => s.locale === slugInfo.locale);
    if (translatedTopicSlug) {
      targetRoute.params = {
        ...targetRoute.params,
        topicSlug: translatedTopicSlug.value,
      };
      targetRoute.query = {
        ...targetRoute.query,
        view: currentRoute.value.query.view,
      };
    }
  }

  return resolve(targetRoute).href;
}
</script>

<style scoped lang="scss">
.lang-switch {
  display: flex;
  color: var(--base-color-foreground-faded);

  > .icon {
    width: 1rem;
    flex-shrink: 0;
    margin-right: 0.75rem;
  }

  > .list {
    display: flex;

    > .item {
      display: flex;
      align-items: center;

      &:not(:first-child)::before {
        content: '/';
        padding-inline: 0.5ch;
      }

      &.-active > .link {
        color: var(--base-color-foreground);
      }

      > .link {
        text-transform: uppercase;
        text-decoration: none;
      }
    }
  }
}
</style>
